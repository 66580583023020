
import NetworkService from '@/core/services/network-service';
import { defineComponent, onMounted, ref } from 'vue';
import BarChart from './BarChart.vue';
import type { DashboardDetails } from '@/models';
import OrdersTable from './orders/orders-table.vue';
import NumberFormatter from '@/components/NumberFormatter.vue';
import Spinner from '@/components/Spinner.vue';
import BannersComponent from '@/components/banners.vue';


export default defineComponent({
  name: "dashboard",
  components: {
    BarChart,
    OrdersTable,
    NumberFormatter,
    Spinner,
    BannersComponent
  },
  setup() {
    const dashboardDetails = ref({} as unknown as DashboardDetails);
    const loading = ref(false);


    const getDashboardDetails = async () => {
      loading.value = true;

      const response = await NetworkService.get('vendor/dashboard/details')
      loading.value = false;

      if (response.status == 200) {
        dashboardDetails.value = response.data;
      }

      loading.value = false;
    }
    onMounted(() => {
      getDashboardDetails();
    })

    return {
      dashboardDetails,
      loading,
    }
  }
});
