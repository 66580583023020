
import { defineComponent, onMounted, ref, Ref } from "vue";
import { setup } from "vue-class-component";
import { OpeningClosingTime } from "@/models"
import { defaultOpeningClosingTime } from '@/core/data/orderData';
import { cloneDeep } from "lodash";
import NetworkService from "@/core/services/network-service";
import { vendorNotification } from '@/main';

import Spinner from "@/components/Spinner.vue";

export default defineComponent({
  components: { Spinner },
    setup() {
        
        const details: Ref<OpeningClosingTime[]> = ref([
            {
                day: "Monday",
                opening_time: null,
                closing_time: null
            },
            {
                day: "Tuesday",
                opening_time: null,
                closing_time: null
            },
            {
                day: "Wednesday",
                opening_time: null,
                closing_time: null
            },
            {
                day: "Thursday",
                opening_time: null,
                closing_time: null
            },
            {
                day: "Friday",
                opening_time: null,
                closing_time: null
            },
            {
                day: "Saturday",
                opening_time: null,
                closing_time: null
            },
            {
                day: "Sunday",
                opening_time: null,
                closing_time: null
            },
        ]);
        const selectOptions = [
            {
                title: '8AM',
                value: 8
            },
            {
                title: "9AM",
                value: 9
            },
            {
                title: "10AM",
                value: 10
            },
            {
                title: '11AM',
                value: 11
            },
            {
                title: "12PM",
                value: 12
            },
            {
                title: "1PM",
                value: 13
            },
            {
                title: "2PM",
                value: 14
            },
            {
                title: "3PM",
                value: 15
            },
            {
                title: "4PM",
                value: 16
            },
            {
                title: "5PM",
                value: 17
            },
            {
                title: "6PM",
                value: 18
            },
            {
                title: "7PM",
                value: 19
            },
            {
                title: "8PM",
                value: 20
            },
            {
                title: "9PM",
                value: 21
            },
            {
                title: "10PM",
                value: 22
            },
            {
                title: "11PM",
                value: 23
            },
        ]
        const isLoading = ref(false)

        const submit = async () => {
            console.log(details.value);
            isLoading.value = true
            const res = await NetworkService.post('vendor/create-or-update', details.value)
            isLoading.value = false

            if (res.status == 200){
                vendorNotification({
                    type: 'success',
                    title: "",
                    text: "Saved Successfully",
                })
                isLoading.value = false
                return
            }
            vendorNotification({
                type: 'error',
                title: "",
                text: res.data.message,
            })
            isLoading.value = false
        }

        const getTimes = async () => {
            isLoading.value = true;
            const res = await NetworkService.get('vendor/opening-closing-time')
            isLoading.value = false
            if(res.data.data.length != 0){
                details.value = res.data.data
            }else{
                vendorNotification({
                type: 'error',
                title: "",
                text: "unable to get time data"
            })
            }
        }

        onMounted( () => {
            getTimes()
        })

        return {
            details,
            selectOptions,
            submit,
            isLoading
        }
    }
})
