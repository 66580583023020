<template>
    <div class="notification-cont">
     <div class="alert alert-success" role="alert" v-if="notification.type == 1">
         {{notification.message}}
     </div>
     <div class="alert alert-danger" role="alert" v-if="notification.type == 2">
         {{notification.message}}
     </div>
    </div>
 </template>
 
 <script>
 import { useStore } from 'vuex';
 import { computed } from '@vue/reactivity';
 export default {
     setup() {
         const store = useStore();
         const notification = computed(() => '');
         console.log(notification.value);
         return {
             notification,
         }
     }
 }
 </script>
 
 <style scoped>
     .notification-cont{
         position: fixed;
         z-index: 9999;
         top: 7%;
         width: 500px;
         right: 50%;
         transform: translateX(50%);
         
     }
 </style>