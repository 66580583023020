
import { computed, defineComponent, ref, watch } from "@vue/runtime-core"
import { useStore } from "vuex";
import { useToggle } from "@/core/composables/useToggle";
import NetworkService from "@/core/services/network-service";
import { vendorNotification } from '@/main';

import { useUser } from "@/core/composables/useUser";
import { isIOSDevice } from "@/core/services/app-service";

import { Actions } from "@/store/enums/StoreEnums";
import { useRouter, useRoute, Router } from "vue-router";
import GoLiveComponent from "@/components/GoLiveComponent.vue";

export default defineComponent({
    components: { GoLiveComponent },
    name: 'navbar',

    setup() {
        
        const store = useStore();
        const user = computed(() => store.getters.currentUser);
        const router = useRouter();
        const openModal = ref(false)
        const loading = ref(false)
        console.log(user.value);
        const message = ref({
            reason: ""
        })

        const deleteAccount = async () => {
            const res = await NetworkService.post('vendor/delete-account', message.value)
            console.log(res.data);

            if (res.status == 200) {
                loading.value = false;
                vendorNotification({
                    type: 'success',
                    title: "OTP",
                    text: "Account deleted successfully",
                })
                openModal.value = true;
                signOut()
                return;
            }
            vendorNotification({
                type: 'error',
                title: "OTP",
                text: res.data.message,
            })
            loading.value = false;
        }

        const signOut = () => {
            store.dispatch(Actions.LOGOUT).then(() => {
                router.push({ name: "login" });
            });
        };


        return { user, openModal, message, deleteAccount }


    }
});
