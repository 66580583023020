
import { defineComponent, onMounted, ref, Ref, computed, PropType } from "vue";
import type { Header, Item, ClickRowArgument } from "vue3-easy-data-table";
import { Settlement, Bank } from '@/models';
import { useSettlement } from '@/core/composables/useSettlement';
import { useRouter } from "vue-router";
import Spinner from "@/components/Spinner.vue";
import { cloneDeep } from "lodash";
import TitledCard from '@/components/cards/TitledCard.vue'
import { defaultSettlement } from '@/core/data/orderData';

export default defineComponent({
    name: "transactions",
    setup() {
        const router = useRouter();
        const isEdit = ref(false);
        const isNull = ref(false);
        const isOtp = ref(false)
        const { getBanks, banks, isLoading, settlement, isNotSettlement, 
            create, getSettlement, isCreated, initiateOtp } = useSettlement();
        const data: Ref<Settlement> = ref(cloneDeep({ ...settlement }) as unknown as Settlement)

        onMounted(async () => {
            await getSettlement()
            await getBanks();
            isNull.value = settlement.value.bank_code == '' ? true : false;
        });

        const submit = async () => {
            if (settlement.value.account_name == '' || settlement.value.account_number == '') return
            let bank = banks.value.filter((e: Bank) => e.code == settlement.value.bank_code)
            settlement.value.bank_name = bank[0].name

            await create(settlement.value)
            isEdit.value = !isCreated.value
        }

        const generateOtp = async () => {
            await initiateOtp();
            isOtp.value = true
        }

        const goBack = () => {
            isEdit.value = false
            if (isNotSettlement) {
                settlement.value = defaultSettlement;
            }
        }

        return {
            isLoading,
            banks,
            settlement,
            submit,
            goBack,
            isEdit,
            isNull,
            isNotSettlement,
            generateOtp,
            isOtp
        };
    },
    components: { Spinner, TitledCard }
});
