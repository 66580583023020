import { useRoute } from "vue-router"

export const isMobileApp = ():boolean=>{
    const route = useRoute(); 
    return Object.keys(route.query).includes('mobileApp');
}

export const isIOSDevice = ():boolean=>{
    const route = useRoute();
    if(!isMobileApp()) return false;

    if(route.query.device_type == 'IOS') return true;
    return false;
}
