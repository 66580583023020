<template>
    <titled-card title="Upsells (Order bump)" :optional="true">
        <template #contentSlot>
            <div class="mb-8">
                <label for="food-meal" class="block font-medium text-[#1E2234] text-sm mb-4">
                    <p class="text-[13px] text-[#acabab] w-[85%]  ">
                    When a customer adds this product to their cart, do you want to recommend up to 4
                    <strong> PREMIUM PRODUCTS</strong> from the same category on your menu instantly that they can also
                    add to their cart. This is great for increasing sales.
                  </p>
                </label>
                <multiselect mode="tags" class="prd_category input mul-select" :options="
                    products.filter((e)=> e.id != id ).map((c) => {
                        return { value: c.id, label: c.name + '-₦' + c.price };
                    })
                " v-model="up_sells"  >
                </multiselect>
            </div>
        </template>
    </titled-card>
</template>

<script>
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import TitledCard from '../cards/TitledCard.vue';
import { useProduct } from '@/core/composables/useProduct';

export default defineComponent({
    components: { TitledCard },
    props: {
        modelValue: {
            type: Array,
            required: true
        },
        id: {
           type: String,
        }
    },
    setup(props, { emit }) {
        const up_sells = computed({
            get: () => props.modelValue,
            set: (val) => emit("update:modelValue", val),
        });
        const { getProducts, products } = useProduct();
    
        onMounted( async ()=> {
            await getProducts();
        })

        return {
            products,
            up_sells,
        }
    }
});
</script>

<style></style>
