import NetworkService from '../services/network-service';

interface AuthComposable {
    getUserDetails:()=> Promise<void>,
}

export const useUser = ():AuthComposable =>{
   const getUserDetails = async()=>{
    try {
        const response = await NetworkService.get("vendor/auth/user");
        if (response.status == 200) {
            let result = encodeURIComponent(JSON.stringify(response.data.user));
            localStorage.setItem('rideeat', result);
        }
    } catch ({ response }: any) {
    }

   }
    return {
        getUserDetails
    }
}