
import HandleDocument from "@/components/HandleDocument.vue";
import { onMounted, ref } from "vue";
import NetworkService from "@/core/services/network-service";
import { vendorNotification } from '@/main';

import { useStore } from "vuex";
import { useUser } from '@/core/composables/useUser';
import FileUploader from "@/components/forms/FileUploader.vue";

export default {
  name: "uplaod-image",
  components: {
    HandleDocument,
    FileUploader
  },
  setup() {
    
    const store = useStore()
    const user = store.getters.currentUser;
    const { getUserDetails } = useUser();
    const logoFile = ref(user.logo_image? [user.logo_image]:[]);
    const bannerFile = ref(user.banner_image? [user.banner_image]: []);
    const logo = ref({
      logo_image: user.logo_image,
      banner_image: user.banner_image,
    });

    const myLogo = (url: any) => {
      logo.value.logo_image = url;
    };

    const myBanner = (url: any) => {
      logo.value.banner_image = url;
    };

    const uploadImage = async () => {

      if (logo.value.banner_image == '' && logo.value.logo_image == '') {
        vendorNotification({
          type: 'warn',
          title: "Update Profile",
          text: 'please select a valid file ',
        })
        return;
      }

      let response = await NetworkService.post("/vendor/update-logo-and-banner", logo.value);

      if (response.status == 200) {
          getUserDetails();
        vendorNotification({
          type: 'success',
          title: "Update Profile",
          text: response.data.message,
        })
        return;
      } 
      vendorNotification({
          type: 'error',
          title: "Update Profile",
          text: response.data.message,
        })
    };

    return {
      myLogo,
      myBanner,
      uploadImage,
      logo,
      logoFile,
      bannerFile,
    };
  },
};
