
import { Actions } from '@/store/enums/StoreEnums';
import { computed, defineComponent, onMounted, ref } from '@vue/runtime-core'; 
import { useRouter, useRoute } from "vue-router";
import { useStore } from 'vuex';

export default defineComponent({
    name: "transaction",
    components: {},
    setup() {
        const route = useRoute()

        const routeName = computed(() => route.name)

        const store = useStore();
        const router = useRouter(); 
        
        const signOut = () => {
            store.dispatch(Actions.LOGOUT).then(() => {
                router.push({ name: "login" });
            });
        };

        
        return {
            routeName
        }
    }
});
