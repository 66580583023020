
import { ref, defineComponent, onMounted, computed } from "vue";
import NetworkService from "@/core/services/network-service";
import type { Header, Item } from "vue3-easy-data-table";
import { vendorNotification } from '@/main';

import Spinner from '@/components/Spinner.vue'
import { useWithdrawal } from "@/core/composables/useWithdrawal";
import { Withdrawal } from '@/models';
import NumberFormatter from "@/components/NumberFormatter.vue";
import { useSettlement } from '@/core/composables/useSettlement';
import TinySpinner from "@/components/TinySpinner.vue";

export default defineComponent({
    name: 'withdrawal',
    components: { Spinner, NumberFormatter, TinySpinner },
    setup() {  
        const otpDetails = ref({
            otp: '',
            amount: ''
        })
        const loading = ref(false)
        const isLoadingWithdraw = ref(false)
        const openModal = ref(false)
        const openSettlementModal = ref(false)
        const { getWithdrawals, withdrawals, paginationData, isLoading
            , getTransferFee, transferFee, transferFeeLoading } = useWithdrawal();
        const { isNotSettlement, getSettlement } = useSettlement();
        const headers: Header[] = [
            { text: "Status", value: "status" },
            { text: "Amount", value: "amount" },
            { text: "Info", value: "status_info" },
            { text: "Date", value: "created_at" },
        ];


        const isFirstPage = ref();
        const isLastPage = ref();
        const next = ref();
        const prev = ref();
        const count = ref(10);
        const items = ref([] as unknown as Withdrawal[]);
        let timer: any = null;

        const getTransferFeeHandler = async () => {
            if (otpDetails.value.amount == '0' || otpDetails.value.amount == '') {
                transferFee.value = 0
                return
            }
            transferFee.value = 0
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            timer = setTimeout(() => {
                getTransferFee(otpDetails.value.amount)
            }, 400);
        }

        const actualAmount = computed(() => Number(otpDetails.value.amount) - Number(transferFee.value))

        const fetchWithdraws = async (page?: number) => {
            await getWithdrawals(page);

            items.value = withdrawals.value;
            isFirstPage.value = 1;
            isLastPage.value = paginationData.value.last_page;
            next.value = paginationData.value.next;
            prev.value = paginationData.value.prev;
        };

        onMounted(async () => {
            await fetchWithdraws(1)
            await getSettlement()


            openSettlementModal.value = isNotSettlement.value ? true : false
        });
        const nextPage = (page: number) => {
            fetchWithdraws(page);
        };

        const prevPage = (page: number) => {
            fetchWithdraws(page);
        };


        const submitWithdrawalDetails = async () => {
            loading.value = true;
            let response = await NetworkService.post('/vendor/initiate-otp', { amount: otpDetails.value.amount });

            if (response.status == 200) {
                loading.value = false;
                vendorNotification({
                    type: 'success',
                    title: "OTP",
                    text: "A 4 digit pin has been sent to your email",
                })
                openModal.value = true;

                return;

            }
            vendorNotification({
                type: 'error',
                title: "OTP",
                text: response.data.message,
            })
            loading.value = false;

        }

        const submitOtpAmount = async () => {
            isLoadingWithdraw.value =true
            let response = await NetworkService.post('/vendor/make-withdrawal', otpDetails.value);

            if (response.status == 200) {
                openModal.value = false;
                vendorNotification({
                    type: 'success',
                    title: "OTP",
                    text: "Withdrawal successful",
                })
                otpDetails.value.amount = ""

                fetchWithdraws(1);
                isLoadingWithdraw.value =false
                return;

            }
            vendorNotification({
                type: 'error',
                title: "OTP",
                text: response.data.message,
            })
            isLoadingWithdraw.value =false
            loading.value = false;
            openModal.value = false;

        }
        return {
            headers,
            items,
            otpDetails,
            isFirstPage,
            isLastPage,
            nextPage,
            prevPage,
            next,
            prev,
            submitWithdrawalDetails,
            submitOtpAmount,
            openModal,
            loading,
            paginationData,
            isLoading,
            getTransferFeeHandler,
            transferFee,
            actualAmount,
            openSettlementModal,
            transferFeeLoading,
            isLoadingWithdraw
        }
    }
})
