
import NetworkService from '@/core/services/network-service';
import { defineComponent, onMounted, ref, } from 'vue';
import type { Banner } from '@/models';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { vendorNotification } from '@/main';

export default defineComponent({
    name: "banners",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const loading = ref(false);
        const banners = ref<Banner[]>([]);
        
        const progressCircle = ref<any>(null);
        const progressContent = ref<any>(null);
        const onAutoplayTimeLeft = (s: any, time: any, progress: any) => {
            progressCircle.value!.style.setProperty('--progress', 1 - progress);
            progressContent.value!.textContent = `${Math.ceil(time / 1000)}s`;
        };
        const getBanners = async () => {
            loading.value = true;
            let response = await NetworkService.get("/vendor/get-configuration?title=vendor_carousel");
            if (response.status == 200) {
                banners.value = response.data;

                loading.value = false;
                return
            }
            loading.value = false;
            vendorNotification({
                type: 'error',
                title: "Unable to fetch banners",
                text: response.data.message,
            })

        }

        onMounted(() => {
            getBanners();
        })

        return {
            onAutoplayTimeLeft,
            progressCircle,
            progressContent,
            banners,
            modules: [Navigation, Autoplay, Pagination,],
        }
    }
});
