import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-054491e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-auto w-[inherit] lg:ml-[280px] md:ml-8 ml-0 lg:mt-[90px] mt-[50px] pt-8" }
const _hoisted_2 = { class: "lg:w-[97%] md:w-[97%] w-[93%] lg:mx-0 md:mx-0 mx-auto max-w-[1435px]" }
const _hoisted_3 = { class: "mb-6 min-h-[70vh]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createVNode(_component_router_view)
          ])
        ])
      ])
    ])
  ]))
}