
import { computed, defineComponent, ref, watch } from "@vue/runtime-core"
import { useStore } from "vuex";
import { useToggle } from "@/core/composables/useToggle";
import NetworkService from "@/core/services/network-service";
import { vendorNotification } from '@/main';

import { useUser } from "@/core/composables/useUser";

export default defineComponent({
    name: 'navbar',

    setup() {
        
        const store = useStore();
        const user = computed(() => store.getters.currentUser);
        const { getUserDetails } = useUser()
        const goLive = ref(user.value.publish == 1 ? true : false);

        const handleGolive = async () => {
            let payLoad = { publish: 0 }
            if (goLive.value == true) {
                payLoad.publish = 1;
            }
            let response = await NetworkService.post('/vendor/publish', payLoad);

            if (response.status == 200) {
                // let result = encodeURIComponent(JSON.stringify(response.data.vendor));

                vendorNotification({
                    type: 'success',
                    title: "Go live",
                    text: response.data.message,
                })
                getUserDetails();
                return;

            }
            vendorNotification({
                type: 'error',
                title: "Go live ",
                text: response.data.message,
            })
            goLive.value = user.value.publish == 1 ? true : false
        }

        return {  goLive, handleGolive }
 

    }
});
