
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/runtime-core";
import { Variant, VariantGroup } from '@/models';
import { useProduct } from '@/core/composables/useProduct';
import { defaultVariant, defaultVariantGroup } from '@/core/data/foodData';
import { cloneDeep } from "lodash";
import VariantGroupModal from "./VariantGroupModal.vue";
import { anyTypeAnnotation } from "@babel/types";
import TitledCard from "../cards/TitledCard.vue";

export default defineComponent({
  components: { VariantGroupModal, TitledCard },
  props: {
    modelValue: {
      type: Array as PropType<VariantGroup[]>,
      required: true
    },
  },
  emits: [
    'updateVariantGroup',
    "update:modelValue",
    "changeOpenVariantGroupModal"
  ],
  setup(props, { emit }) {
    const variant_groups = computed({
      get: () => props.modelValue,
      set: (val) => emit("update:modelValue", val),
    });
    const showExistingVariant = ref(false);
    const showAddNewVariant = ref(false);
    const { getVariants, existingVariants, variantGroups, createVariantGroup } = useProduct();
    const inputedVariant = ref({ ...cloneDeep(defaultVariant) } as unknown as Variant);
    const selectedVariant = ref({
      variant_group_id: null,
      variant_id: null
    });
    const newSelectedVariGrp = ref(null);
    const variantGroup = ref({ ...defaultVariantGroup } as VariantGroup);
    const selectedVariantGroups = ref([] as VariantGroup[])
    const openExistingVariantModal = ref(false)
    const openNewVariantModal = ref(false)
    const openVariantGroupModal = ref(false)


    const addSelectedVariant = (variant: Variant | null) => {
      let vari: any;
      let vari_group: any;
      let selected_vari_grp: any;

      //Existing Variant
      if (variant == null) {
        vari = existingVariants.value.find((e) => e.id == selectedVariant.value.variant_id) //Get selected Variant
        vari_group = variantGroups.value.find((e) => e.id == selectedVariant.value.variant_group_id) //Get selected Variant Group

        //Check if Variant Group already existed in Product variant_groups 
        selected_vari_grp = variant_groups.value?.find((e) => e.id == selectedVariant.value.variant_group_id) 
        
      } else {// Create new variant
        vari = variant;
        vari_group = variantGroups.value.find((e) => e.id == newSelectedVariGrp.value)
        selected_vari_grp = variant_groups.value?.find((e) => e.id == newSelectedVariGrp.value)
      }

      //first time adding group into product
      //add group and add new variant
      if (selected_vari_grp == undefined) {
        variant_groups.value?.push({
          id: vari_group?.id,
          title: vari_group?.title,
          required: true,
          no_of_selection: 1,
          variants: [{
            name: vari!.name,
            price: vari!.price
          }]
        } as VariantGroup)
      }
      else {// Group already exist, then add variant
        selected_vari_grp.variants.push({
          name: vari!.name,
          price: vari!.price
        } as Variant)
      }
      openExistingVariantModal.value= false
      openNewVariantModal.value = false
      console.log(variant_groups.value);
    }

    const removeVariant = (variant_index: number, grp_index: number) => {
      let groups = variant_groups.value;
      groups[grp_index].variants.splice(variant_index, 1)
      variant_groups.value = groups;
    }

    const toggleEditVariant = (variantt: Variant) => {
      return variantt.edit_variant = true;
    }

    const addVariantGroup = async (data: VariantGroup) => {
      const variantGroup = await createVariantGroup(data)
      emit("updateVariantGroup", variantGroup);
      openVariantGroupModal.value = false
    }

    const closeVariantGroupModal = (val: boolean) => {
      openVariantGroupModal.value = val
    }

    const removeVariantGroup = (indexx: number) => {
      variant_groups.value = variant_groups.value.filter((v, index) => index != indexx )
    }

    onMounted(async () => {
      await getVariants();
     
    })

    return {
      inputedVariant,
      removeVariant,
      addVariantGroup,
      toggleEditVariant,
      existingVariants,
      showExistingVariant,
      showAddNewVariant,
      selectedVariant,
      addSelectedVariant,
      variantGroups,
      variantGroup,
      newSelectedVariGrp,
      variant_groups,
      removeVariantGroup,
      openExistingVariantModal,
      openNewVariantModal,
      openVariantGroupModal,
      closeVariantGroupModal
    }
  }
});
