import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { Transaction, ResponseMeta } from '@/models';
import { cloneDeep } from "lodash";
import { vendorNotification } from '@/main';

import { defaultTransaction, defaultResponseMeta } from '@/core/data/orderData';

interface TransactionComposable {
    isLoading: Ref<boolean>,
    transactions:Ref<Transaction[]>,
    transaction: Ref<Transaction>,
    getTransaction: (uuid:any) => void;
    getTransactions: (page?:number, search?:any) => void;
    paginationData: Ref<ResponseMeta>;
}

export const useTransaction = (): TransactionComposable => {
    const isLoading = ref(false);
    const transaction: Ref<Transaction> = ref(cloneDeep(defaultTransaction) as unknown as Transaction);
    const transactions: Ref<Transaction[]> = ref([]);
    const paginationData: Ref<ResponseMeta> = ref(cloneDeep(defaultResponseMeta) as unknown as ResponseMeta);

    const getTransactions = async (page?:number, search?:any) => {
        isLoading.value = true
        let response = await NetworkService.get('vendor/transaction?page='+page+'&search='+search);
        transactions.value = response.data.data.map((e:any)=>{
            e.extra = JSON.parse(e.extra)
            return e;
        })
        
        
        let meta = {...response.data.links, ...response.data.meta}
        paginationData.value = meta
        isLoading.value = false
    }
    const getTransaction = async (uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.get('vendor/transaction/' + uuid);
        let data = response.data.data;

        data.extra =  JSON.parse(data.extra)
        
        transaction.value = data;
        isLoading.value = false;
    }


    return {
        isLoading,
        transaction,
        transactions,
        paginationData,
        getTransactions,
        getTransaction,                                                                                      
    }
}