export const defaultFood = {
    name: "",
    price: null,
    striked_price: null,
    preparation_time: "",
    category_id: null,
    sub_category_id: null,
    out_of_stock:false,
    variant_groups: [],
    // quantity: null,
    image: null,
    description: "",
    up_sells: [],
    cross_sells: [],
}

export const defaultVariant = {
    name: '',
    price: 0,
    edit_variant: false,
}

export const defaultVariantGroup = {
    title: '',
    required: false,
    no_of_selection: 1,
    variants: [],
}

export const defaultCategory = {
    id: null,
    title: '',
    parent_id: null,
    category_id: null
}