
import { defineComponent } from "vue";
export default defineComponent({
    name: "confirmationModal",
    components: {},
    props: {
        title: {
            type: String,
            required: true,
        },
        actionText: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: true
        }
    },
    emits: [
        'action',
    ],
    setup(props, { emit }) {
        const triggerAction = () => {
            console.log('hello');
            
            emit("action")
        }
        return {
            triggerAction
        }
    }
})

