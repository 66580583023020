import { useRouter, useRoute } from "vue-router";

export function RequestErrors(response: any) {
  const router = useRouter();

  if (response.data.errors) return { ...response.data.errors };

  if (response.data.message && response.data.message != "")
    return { message: response.data.message };

  if (response.status == 401 && window.location.pathname != "/login")  { 
    router.push({name: "login"}) 
  };


  if (response.status === 404) return { message: "Resource route not found" };

  if (response.status === 500) return { message: "Internal server error" };
}
