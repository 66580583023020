
import { computed, defineComponent, onMounted, PropType, ref, watch } from '@vue/runtime-core';
import ProductVariant from '@/components/foods/products-variant.vue';
import { useProduct } from '@/core/composables/useProduct';
import { flatten } from 'lodash';
import { vendorNotification } from '@/main';

import TitledCard from '@/components/cards/TitledCard.vue';
import HandleDocument from '@/components/HandleDocument.vue';
import { VariantGroup, Category, Product } from '@/models';
import { defaultVariantGroup } from '@/core/data/foodData';
import Upsells from './Upsells.vue';
import CrossSells from './CrossSells.vue';
import FileUploader from '../forms/FileUploader.vue';

export default defineComponent({
    name: "transaction",
    components: { ProductVariant, TitledCard, HandleDocument, Upsells, CrossSells, FileUploader },
    props: {
        modelValue: {
            type: Object as PropType<Product>,
            required: true,
        },
        editMode:{
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'submit',
        "update:modelValue"
    ],
    setup(props, { emit }) {
        const product = computed({
            get: () => props.modelValue,
            set: (val) => emit("update:modelValue", val),
        });

        const { getCategories, categories, getProducts, products } = useProduct();
        const outOfStock = ref((product.value.out_of_stock) ? true : false);
        const showVariant = ref(false);
        const showQuantity = ref(false);
        const unlockEdit = ref(false);
        

        const subCategories = computed(() => {
            return categories.value.find((e) => e.id == product.value.category_id)?.sub_categories;
        });

        const unsetQuantity = () => {
            if (showQuantity.value == false) return product.value.quantity = null;
        }

        onMounted(async () => {
            await getCategories()
            await getProducts();
        });

        const createProduct = async () => {
            if(product.value.image == null){
                vendorNotification({
                    type: 'error',
                    title: "Status",
                    text: "Please upload an image",
                })
                return
            }
            
            emit("submit", product.value);
        }

        const updateVariantGroup = (variantGroup: VariantGroup) => {
            console.log(variantGroup);
            const data = {
                ...defaultVariantGroup,
                ...variantGroup
            };
            product.value.variant_groups.push({ ...data } as VariantGroup)
        }

        const myFunc = (url: any) => {
            product.value.image = url;
            console.log("product image", url)
        }

        const onChangeCategory = () => {
            product.value.sub_category_id = null;
        } 
        watch(outOfStock,()=>{
            product.value.out_of_stock = !product.value.out_of_stock;
        })
        return {
            myFunc,
            categories,
            product,
            subCategories,
            createProduct,
            showVariant,
            showQuantity,
            unsetQuantity,
            updateVariantGroup,
            onChangeCategory,
            outOfStock,
            products
        }
    }
});
