export const defaultOrder = {
    delivery_to: "",
    to_coordinate: "",
    delivery_from: "",
    from_coordinate: "",
    delivery_fee: null,
    duration: null,
    delivery_distance: "",
    total: null,
    sub_total: null,
    delivery_method: "",
    foods: {},
    note: "",
    status: null,
    flow: {},
    code: null,
    description: "",
}

export const defaultResponseMeta = {
    per_page: null,
    total: null,
    last_page: null,
    current_page: null,
    next: null,
    prev: null,
    first: null,
    last: null,
    from: null,
    path: null,
    to: null,
}

export const defaultTransaction = {
    wallet_id: null,
    resource_id: null,
    description: "",
    balance_before: null,
    balance_after: null,
    type: "",
    extra: null
}
export const defaultWithdrawal = {
    balance_before: null,
    balance_after: null,
    created_at: null,
    status: "",
}

export const defaultOpeningClosingTime = {
    day: "",
    opening_time: null,
    closing_time: null,
}

export const defaultSettlement = {
    bank_code: "",
    bank_name: "",
    account_name: "",
    account_number: "",
    approve: false,
}