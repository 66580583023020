<template>
    <titled-card title="Cross-sells (bought together)" :optional="true">
        <template #contentSlot>
            <div class="mb-8">
                <label for="food-meal" class="block font-medium text-[#1E2234] text-sm mb-4">
                    <p class="text-xs text-[#acabab] w-[85%]  ">
                        On your Restaurant menu, would you like to mark up to 4 other products/meals most customers buy
                        together with this product?
                    </p>
                </label>
                <multiselect mode="tags" class="prd_category input mul-select" :options="
                    products.filter((e)=> e.id != id ).map((c) => {
                        return { value: c.id, label: c.name + '-₦' + c.price };
                    })
                " v-model="cross_sells">
                </multiselect>
            </div>
        </template>
    </titled-card>
</template>

<script>
import { computed, defineComponent, onMounted, PropType } from 'vue'
import TitledCard from '../cards/TitledCard.vue';
import { useProduct } from '@/core/composables/useProduct';

export default defineComponent({
    components: { TitledCard },
    props: {
        modelValue: {
            type: Array,
            required: true
        },
        id: {
           type: String,
        }
    },
    setup(props, { emit }) {
        const cross_sells = computed({
            get: () => props.modelValue,
            set: (val) => emit("update:modelValue", val),
        });
        const { getProducts, products } = useProduct();

        onMounted(async () => {
            await getProducts();
            // console.log(products.value);
        })

        return {
            products,
            cross_sells
        }
    }
});
</script>

<style></style>
