
import { computed, defineComponent } from 'vue';

export default defineComponent({
    props:{
        amount: {type: Number, required:true}
    },
    setup(props){
        
        const amount = computed(()=> Intl.NumberFormat('en-us', { style: "currency", currency: "NGN" }).format(props.amount))

        return {
            amount
        }
    }
})
