import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { Settlement, Bank, ResponseMeta } from '@/models';
import { cloneDeep } from "lodash";
import { vendorNotification } from '@/main';

import { defaultSettlement, defaultResponseMeta } from '@/core/data/orderData';

interface SettlementComposable {
    isLoading: Ref<boolean>,
    isCreated: Ref<boolean>,
    isNotSettlement: Ref<boolean>,
    settlement: Ref<Settlement>,
    create: (data:any) => void;
    banks: Ref<Bank[]>;
    getBanks: () => void;
    getSettlement: () => void;
    initiateOtp: () => void;
}

export const useSettlement = (): SettlementComposable => {
    const isLoading = ref(false);
    const isCreated = ref(false);
    const isNotSettlement = ref(false);
    const banks: Ref<Bank[]> = ref([]);
    const settlement: Ref<Settlement> = ref(cloneDeep(defaultSettlement) as unknown as Settlement);
    

    const create = async (data:any) => {
        isLoading.value = true
        isCreated.value = false
        let response = await NetworkService.post('vendor/update-or-create', data);
        
        if (response.status == 201 || response.status == 200) {
            vendorNotification({
                type: 'success',
                text: 'Request Successful',
                title: 'Settlement'

            })
            isCreated.value = true
        }else{
            vendorNotification({
                type: 'error',
                text: response.data.message,
                title: 'Settlement'

            })
            isCreated.value = false;
        }
    
        getSettlement();
        isLoading.value = false
    }
    
    const getBanks =async () => {
        let response = await NetworkService.get('banks');
        banks.value = response.data ?? []
    }

    const getSettlement =async () => {
        isLoading.value = true
        let response = await NetworkService.get('vendor/get-account');

        if (!response.data.data) {
            isNotSettlement.value = true
        }

        settlement.value = response.data.data ?? defaultResponseMeta
        isLoading.value = false
    }

    const initiateOtp = async () => {
        isLoading.value = true

        let response = await NetworkService.get('vendor/initiate-otp');

        if (response.status == 201 || response.status == 200) {
            vendorNotification({
                type: 'success',
                text: response.data.message,
                title: 'Settlement'

            })
        }else{
            vendorNotification({
                type: 'error',
                text: response.data.message,
                title: 'Settlement'

            })
        }

        isLoading.value = false
    }

    return {
        isLoading,
        settlement,
        create,
        banks,
        isCreated,
        getBanks,
        getSettlement,
        isNotSettlement,
        initiateOtp                                                                          
    }
}