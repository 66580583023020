import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { Withdrawal, ResponseMeta } from '@/models';
import { cloneDeep } from "lodash";
import { vendorNotification } from '@/main';

import { defaultWithdrawal, defaultResponseMeta } from '@/core/data/orderData';

interface WithdrawalComposable {
    isLoading: Ref<boolean>,
    transferFeeLoading: Ref<boolean>;
    withdrawals:Ref<Withdrawal[]>,
    withdrawal: Ref<Withdrawal>,
    transferFee: Ref<Number>,
    getWithdrawal: (uuid:any) => void;
    getWithdrawals: (page?:number) => void;
    getTransferFee: (amount:any) => void;
    paginationData: Ref<ResponseMeta>;
}

export const useWithdrawal = (): WithdrawalComposable => {
    const isLoading = ref(false);
    const transferFeeLoading = ref(false);
    const transferFee = ref();
    const withdrawal: Ref<Withdrawal> = ref(cloneDeep(defaultWithdrawal) as unknown as Withdrawal);
    const withdrawals: Ref<Withdrawal[]> = ref([]);
    const paginationData: Ref<ResponseMeta> = ref(cloneDeep(defaultResponseMeta) as unknown as ResponseMeta);

    const getWithdrawals = async (page?:number) => {
        isLoading.value = true
        let response = await NetworkService.get('vendor/withdrawals?page='+page);
       
        withdrawals.value = response.data.data
        
        
        let meta = {...response.data.links, ...response.data.meta}
        paginationData.value = meta
        isLoading.value = false
    }

    const getWithdrawal = async (uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.get('vendor/order/' + uuid);
        let data = response.data.data;
        withdrawal.value = data;
        isLoading.value = false;
    }

    const getTransferFee = async (amount:any) => {
        transferFeeLoading.value = true;
        let response = await NetworkService.get('vendor/transfer/fee/' + amount);

        if(response.data.status == "success"){
            transferFee.value = response.data.data[0].fee
        }
        
        transferFeeLoading.value = false;
    }
    

    return {
        isLoading,
        paginationData,
        getWithdrawal, 
        getWithdrawals, 
        withdrawals,
        withdrawal,
        transferFee,
        getTransferFee ,
        transferFeeLoading                                                                              
    }
}