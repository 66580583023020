
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/runtime-core";
import { Variant, VariantGroup } from '@/models';
import { useProduct } from '@/core/composables/useProduct';
import { defaultVariant, defaultVariantGroup } from '@/core/data/foodData';
import { cloneDeep } from "lodash";
import { vendorNotification } from '@/main';


export default defineComponent({
    props:{
        openVariantGroupModal: {
            type: Boolean,
        }
    },
    emits: [
        'addNewVariantGroup',
        'updateOpenVariantGroupModal'
    ],
    setup(props, { emit }) {
        const changeOpenVariantGroupModal = () => {
            emit('updateOpenVariantGroupModal', false)
        }
        const { variantGroups, createVariantGroup } = useProduct();
        const variantGroup = ref({ ...defaultVariantGroup } as VariantGroup);
        

        const addVariantGroup = async () => {
            if(variantGroup.value.title == '' ){
                vendorNotification({
                    type:'error',
                    text:'Title must be filled',
                    title:'Varient'
                })

                return;
            }
            emit("addNewVariantGroup", variantGroup.value);
            variantGroup.value = {
                title: '',
                required: false,
                no_of_selection: 1,
            } as VariantGroup
        }

        return {
            variantGroup,
            addVariantGroup,
            changeOpenVariantGroupModal
        }
    }
});
