
import TitledCard from '@/components/cards/TitledCard.vue'
import { useFood } from '@/core/composables/useFood';
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import Spinner from '@/components/Spinner.vue';

export default defineComponent({
    components: { TitledCard, Spinner },
               
    setup() { 
        const { food, getFood, loading } = useFood();
        const uuId = useRoute().params.uuid;

        onMounted(() => { 
            getFood('/vendor/food/' + uuId);
        });
          
        return {
            loading,
            food
        }

    },
})
