
import { computed, defineComponent, nextTick, onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
import { onBeforeMount } from 'vue';
import { useRoute, useRouter } from "vue-router";
import ErrorNotification from "./components/ErrorNotification.vue";
import { isIOSDevice, isMobileApp } from "@/core/services/app-service";

export default defineComponent({
  name: 'App',
  components: {
    ErrorNotification,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isMobile = ref(isMobileApp())

    onBeforeMount(() => {
      if (route.meta.AuthRequired || route.matched.find((d) => d.meta.AuthRequired)) {
        var user = localStorage.getItem('rideeat');
        if (!user) {
          router.push({ name: 'login' })
          return;
        }
        user = decodeURIComponent(user);
        user = JSON.parse(user);
        store.commit('setUser', user);
      }
    })
    
    return {
      isMobile
    }
  }
})

