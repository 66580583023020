
import { defineComponent, onMounted, ref, computed } from "vue";
import OrdersTable from "./orders-table.vue";


export default defineComponent({
    name: "orders",
    setup() {
        
    },
    components: { OrdersTable }
});
