import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_food_form = _resolveComponent("food-form")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_Spinner, {
        key: 0,
        class: "mt-9"
      }))
    : (_openBlock(), _createBlock(_component_food_form, {
        key: 1,
        modelValue: _ctx.product,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.product) = $event)),
        onSubmit: _ctx.createOrUpdateProduct,
        editMode: true
      }, null, 8, ["modelValue", "onSubmit"]))
}