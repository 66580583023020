import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_food_form = _resolveComponent("food-form")!

  return (_openBlock(), _createBlock(_component_food_form, {
    modelValue: _ctx.product,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.product) = $event)),
    onSubmit: _ctx.createOrUpdateProduct
  }, null, 8, ["modelValue", "onSubmit"]))
}