import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "w-full flex monthly_revenue" }
const _hoisted_3 = { class: "w-full monthly-rev my-3 sm:bg-vendorPrimaryLte sm:p-4 p-0 sm:rounded-3xl" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_ctx.banners.length > 0)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_swiper, {
              "slides-per-view": 1,
              pagination: true,
              modules: _ctx.modules,
              autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
              breakpoints: {
                    '640': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '868': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '1024': {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                },
              class: "h-full"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.banners, (banner, idx) => {
                  return (_openBlock(), _createBlock(_component_swiper_slide, { key: idx }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        href: banner.value,
                        target: "_blank",
                        class: "block h-[226px] sm:h-[350px]"
                      }, [
                        _createElementVNode("img", {
                          src: banner.url,
                          class: "block w-full h-full sm:rounded-3xl object-contain sm:object-cover",
                          alt: "..."
                        }, null, 8, _hoisted_5)
                      ], 8, _hoisted_4)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["modules"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}