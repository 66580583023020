
import { computed, defineComponent, nextTick, onMounted, ref, watch } from "vue";
import Navbar from '../components/Navbar.vue';
import Sidebar from '../components/Sidebar.vue';
import { useStore } from "vuex";
import { onBeforeMount } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useUser } from "@/core/composables/useUser";
import { isIOSDevice, isMobileApp } from "@/core/services/app-service";
import BottomNavigation from '../components/BottomNavigations.vue';
export default defineComponent({
  name: 'App',
  components: {
    Navbar,
    Sidebar,
    BottomNavigation
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { getUserDetails } = useUser();

    const isMobileApplication = computed(() => isIOSDevice());

    onMounted(() => {
       getUserDetails(); 
    })
    onBeforeMount(() => {
      if (route.meta.AuthRequired || route.matched.find((d) => d.meta.AuthRequired)) {
        var user = localStorage.getItem('rideeat');
        if (!user) {
          router.push({ name: 'login' })
          return;
        }
        user = decodeURIComponent(user);
        user = JSON.parse(user);
        store.commit('setUser', user);
      }
    })

    return { isMobileApplication }
  }
})

