import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { Product, Variant, VariantGroup, Category, ResponseMeta } from '@/models';
import { cloneDeep } from "lodash";
import { vendorNotification } from '@/main';

import { defaultFood, defaultVariantGroup, defaultCategory } from '@/core/data/foodData';
import { defaultResponseMeta } from '@/core/data/orderData';
import { useRoute, useRouter } from 'vue-router';

interface ProductComposable {
    categories: Ref<Category[]>;
    getCategories: () => void;
    product: Ref<Product>;
    create: (productData: Product) => void;
    getVariants: () => void;
    existingVariants: Ref<Variant[]>;
    createVariantGroup: (data: VariantGroup) => void;
    variantGroups: Ref<VariantGroup[]>;
    getProduct: (uuid: any) => void;
    getProducts: (page?:number, search?:any) => void;
    deleteProduct: (uuid: string) => void
    loading: Ref<Boolean>;
    products: Ref<Product[]>;
    paginationData: Ref<ResponseMeta>;
}


export const useProduct = (): ProductComposable => {
    const paginationData: Ref<ResponseMeta> = ref(cloneDeep(defaultResponseMeta) as unknown as ResponseMeta);
    const product: Ref<Product> = ref(cloneDeep(defaultFood) as unknown as Product);
    const categories = ref([]);
    
    const existingVariants = ref([]);
    const variantGroups = ref([]);
    const loading = ref(true);
    const products: Ref<Product[]> = ref([]);
    const router = useRouter()


    const create = async (productData: Product) => {

        // update or create food
        const response = productData.uuid
            ? await NetworkService.update(`vendor/food/${productData.uuid}`, productData)
            : await NetworkService.post("vendor/food", productData)

        if (response.status == 201 || response.status == 200) {
            vendorNotification({
                type: 'success',
                text: 'Food created/updated successfully',
                title: 'Food'
            })

            router.push({name: "foods"})  
        }else{
            vendorNotification({
                type: 'error',
                text: 'hello',
                title: 'Food'

            })
        }
        return response.data;
    }

    // get product for updating
    const getProduct = async (uuid: any) => {
        loading.value = true;
        const response = await NetworkService.get(`vendor/food/${uuid}`);
        loading.value = false;
        product.value = response.data.data as Product;
        product.value.up_sells = response.data.data.up_sells.map((e:any)=> e.id);
        product.value.cross_sells = response.data.data.cross_sells.map((e:any)=> e.id);
        product.value.variant_groups = response.data.data.variant_groups.map((group: any) => {
            group.required = group.pivot.required == 1 ? true : false;
            group.no_of_selection = group.pivot.no_of_selection
            return group;
        }) as VariantGroup[]

    }

    const createVariantGroup = async (data: VariantGroup) => {
        const response = await NetworkService.post("vendor/create-variant-group", data);
        variantGroups.value = response.data.variant_groups;
        return response.data.variant_group;
    }

    const getCategories = async () => {
        const response = await NetworkService.get("vendor/categories");
        categories.value = response.data.categories
    }

    const getVariants = async () => {
        const response = await NetworkService.get("vendor/variants");
        existingVariants.value = response.data.variants
        variantGroups.value = response.data.variant_groups
    }

    // Get Products
    const getProducts = async (page?:number, search?:any) => {
        let response = await NetworkService.get('vendor/food?page='+page+'&search='+search);
        loading.value = false;
        products.value = response.data.data;

        let meta = {...response.data.links, ...response.data.meta}
        paginationData.value = meta
    }
    const deleteProduct = async (uuid: string) =>{
        loading.value = true;
        let response = await NetworkService.delete('vendor/food/'+uuid);
        if (response) {
            console.log(response);
        }
        loading.value = false;
    }

    return {
        categories,
        getCategories,
        product,
        create,
        getVariants,
        existingVariants,
        createVariantGroup,
        variantGroups,
        getProduct,
        getProducts,
        deleteProduct,
        products,
        loading,
        paginationData,
    }
}