
import TextInput from '@/components/forms/TextInput.vue';
import { defineComponent, onMounted, ref } from '@vue/runtime-core';
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { vendorNotification } from '@/main';

import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { isMobileApp } from '@/core/services/app-service';

export default defineComponent({
    components: { TextInput },
    setup() {
        
        const store = useStore();
        const loading = ref(false);

        const isMobile = ref(false);
        const router = useRouter();
        const details = ref({
            email: '',
            password: '',
            device: null,
        })

        const submit = async () => {
            loading.value = true;

            try {
                details.value.device = JSON.parse(localStorage.getItem('device') || 'null');
                let { status, data } = await store.dispatch(Actions.LOGIN, details.value);
                if (status == 200) {
                    loading.value = false;
                    if (isMobile) {
                        window.location.href = '/home';
                        return
                    }
                    window.location.href = '/dashboard';
                }
                else {
                    vendorNotification({
                        type: 'error',
                        title: "Authorization",
                        text: data.message
                    })
                    loading.value = false;
                    return
                }
            } catch (e: any) {
                vendorNotification({
                    type: 'error',
                    title: "Authorization",
                    text: 'Connection error'
                })
                loading.value = false;
            }
        }
        onMounted(() => {
            store.commit('purge_user')
            checkScreenSize();
        })


        const checkScreenSize = () => {
            const screenWidth = window.innerWidth;
            isMobile.value = screenWidth > 768;
        };

        return {
            loading,
            details,
            submit,
        }
    }
});


