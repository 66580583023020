import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative z-0 w-full mb-6 group" }
const _hoisted_2 = ["type", "id", "required"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: _ctx.type,
      id: _ctx.label,
      class: "block py-3 pl-5 pr-10 email pwd rounded-lg w-full text-sm text-gray-900 bg-transparent border border-[#B4B4B4] focus:ring-vendorPrimary focus:border focus:border-vendorPrimary appearance-none focus:outline-none focus:ring-0 peer",
      placeholder: " ",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      required: _ctx.required,
      autofocus: "false"
    }, null, 8, _hoisted_2), [
      [_vModelDynamic, _ctx.value]
    ]),
    _createElementVNode("label", {
      for: _ctx.label,
      class: "peer-focus:font-medium absolute text-sm text-gray-800 dark:text-gray-400 duration-300 pl-5 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-5 peer-focus:pl-0 peer-focus:text-vendorPrimary peer-focus:text-base peer-focus:bg-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
    }, _toDisplayString(_ctx.label), 9, _hoisted_3),
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass('fa-solid fa-at absolute top-3.5 right-4 lock-icon icon '+_ctx.icon)
        }, null, 2))
      : _createCommentVNode("", true)
  ]))
}