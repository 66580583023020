import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sm:px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_BottomNavigation = _resolveComponent("BottomNavigation")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Navbar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_sidebar),
      _createVNode(_component_BottomNavigation)
    ])
  ]))
}