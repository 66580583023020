
import UseHandleFiles from "@/core/composables/useFiles";

import { defineComponent, watch } from 'vue'

export default defineComponent({
    emits:['uploaded'], 
    
    setup(props, { emit }) {
        
    const { handleFiles, fileResponse } = UseHandleFiles();

    watch(fileResponse, () => {
           if (fileResponse.value) {
            emit('uploaded', fileResponse.value);
           } 
    });

    return { handleFiles}
    },
})
