
import {  defineComponent, onMounted, ref } from '@vue/runtime-core';
import ProductVariant from '../../components/foods/products-variant.vue';
import { useProduct } from '@/core/composables/useProduct';
import TitledCard from '@/components/cards/TitledCard.vue';
import HandleDocument from '@/components/HandleDocument.vue';
import { Product } from '@/models';
import FoodForm from '@/components/foods/FoodForm.vue';
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
name: "transaction",
components: { ProductVariant, TitledCard, HandleDocument, FoodForm },
setup() {
  const { product, create } = useProduct();
  const showVariant = ref(false);
  const showQuantity = ref(false);
  const router = useRouter();
  const route = useRoute()
 
  const createOrUpdateProduct = async (data: Product) => {
    // delete data.up_sells;
    // delete data.cross_sells;

    if(!data.image){

      return;
    }

    await create(data)
  }


  return {
    product,
    createOrUpdateProduct,
    showVariant,
    showQuantity,
  }
}
});
