
import { defineComponent, onMounted, ref, PropType } from 'vue';
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, Filler, LineElement, CategoryScale, LinearScale } from 'chart.js'
import type {  ChartData } from '@/models';
import NetworkService from '@/core/services/network-service';

ChartJS.register(Title, Tooltip, Legend, PointElement, Filler, LineElement, CategoryScale, LinearScale )

export default defineComponent({
  name: 'BarChart',
  components: { Line },
  props: {
    chartData: {
      type: Object as PropType<ChartData> ,
      required: true
    }
  },
  setup(props) {
    const chartData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          data: props.chartData.data,
          backgroundColor: '#12131414',
          label: 'Orders',
          borderRadius: 8,
          borderSkipped: false,
          barPercentage: 0.8,
          borderWidth: 1,
          hoverBackgroundColor: '#FF8900',
          color: '#000000',
          borderColor: '#12131414',
          fill: true,
        }
      ]
    }
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
          title: {
            display: true,
            color: 'rgba(0, 0, 0, 1)', // Change the text color
          },
        },
      scales: {
          x: {
            ticks: {
              color: 'black'
            },
            grid: {
              display: false, // Hide x-axis grid lines
            },
          },
          y: {
            grid: {
              display: false, // Hide y-axis grid lines
            },
            ticks: {
              precision: 0, // Set the precision to 0 for integers
              color: 'black',
            },
          },
        },
    }
    const ticks = {
      major: {
        enabled: true
      },
    }


    return {
      chartData,
      chartOptions,
      ticks
    }
  },
})
