import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import Photos from '@/views/Photos.vue';
import Order from '@/views/orders/order.vue';
import CallDelivery from '@/views/Call-delivery.vue'; 
import Promotions from '@/views/Promotions.vue'; 
// import Transactions from '@/views/Transactions.vue';
import Settings from '@/views/settings/Settings.vue';
import BarChart from '@/views/BarChart.vue';  
import Login from '@/views/auth/login.vue';
import SignUp from '@/views/auth/SignUp.vue';
import Index from '@/views/index.vue';
import ForgotPassword from '@/views/auth/forgotPassword.vue';
import ResetPassword from '@/views/auth/resetPassword.vue';
import CreateFood from '@/views/food/create.vue';
import EditFood from '@/views/food/edit.vue';
import ViewFood from '@/views/food/view.vue';
import ShowView from '@/views/food/show.vue';
import EditAddress from '@/views/EditAddress.vue';
import UplaodImage from '@/views/settings/UploadImage.vue';
import Orders from '@/views/orders/orders.vue'; 
import Transactions from '@/views/transactions/transactions.vue';
import TransactionView from '@/views/transactions/transaction.vue';
import Withdraw from '@/views/withdrawal/Withdraw.vue';
import OpeningClosingTime from "@/views/settings/OpeningClosingTime.vue"
import Home from '@/views/Home.vue'
import SettingsIndex from '@/views/settings/Index.vue';
import Profile from "@/views/Profile.vue"
import Settlement from "@/views/settlement/settlement.vue"

const routes = [
  {
    path: "/",
    name: "layout",
    component: Index,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: '/photos',
        name: 'photos',
        component: Photos
      },
      {
        path: '/profile',
        name: 'profile',
        component: Profile
      },
      {
        path: '/foods',
        name: 'foodIndex',
        component: () => import("@/components/InnerLayout.vue"),
        children:[
          {
            path: '',
            name: 'foods',
            component: ViewFood,
          },
          {
            path: 'create',
            name: 'CreateFood',
            component: CreateFood,
          },
          {
            path: 'edit/:uuid',
            name: 'EditFood',
            component: EditFood,
          },
          {
            path: 'view/:uuid',
            name: 'ShowView',
            component: ShowView
          }
        ]
      },
      {
        path: '/orders',
        name: 'orderIndex',
        component: () => import("@/components/InnerLayout.vue"),
        children:[
          {
            path: '',
            name: 'orders',
            component: Orders,
          },
          {
            path: 'view/:uuid',
            name: 'viewOrder',
            component: Order
          },
        ]
      },
      {
        path: '/call-delivery',
        name: 'call-delivery',
        component: CallDelivery
      },
      {
        path: '/promotions',
        name: 'promotions',
        component: Promotions
      },
      {
        path: '/transactions',
        name: 'transactions',
        component: () => import("@/components/InnerLayout.vue"),
        children: [
          {
            path: '',
            name:'transactions.index',
            component: Transactions
          },
          {
            path: 'view/:uuid',
            name:'transactions.show',
            component: TransactionView
          }
        ]
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        children: [
          {
            path: '',
            name: 'settings.index',
            component: SettingsIndex
          },
          // {
          //   path: '/edit-address',
          //   name: 'edit-address',
          //   component: EditAddress
          // },
          {
            path: '/upload-image',
            name: 'upload-image',
            component: UplaodImage
          },
          {
            path: 'opening-closing-time',
            name: 'opening-closing-time',
            component: OpeningClosingTime
          },
        ]
      }, 
      {
        path: '/bar-chart',
        name: 'bar-chart',
        component: BarChart
      },
      {
        path: '/withdraw',
        name: 'withdraw',
        component: Withdraw
      },
      {
        path: '/settlement',
        name: 'settlement',
        component: Settlement
      },
    ],
    meta: {
        AuthRequired: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login 
  }, 
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword 
  }, 
  {
    path: '/reset-password/:id',
    name: 'resetPassword',
    component: ResetPassword 
  }, 
  {
    path: '/sign-up',
    name: 'sign-up',
    component:SignUp
  },
] as RouteRecordRaw[]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
})

export default router;

router.beforeEach((to,from,next)=>{
  
  if(to.matched.some(record => record.meta.AuthRequired)) {
      if (localStorage.getItem('rideeat') == null) {
          if(to.meta.AllowNoAuth){
            next();
            return;
          }
          next({
              path: '/login',
              name:'login'
          })
      }
      
      
      //if mobile app then make the query consistent on all pages
      if(Object.keys(from.query).includes('mobileApp') && !Object.keys(to.query).includes('mobileApp')){
        next({path: `${to.fullPath}`, query:from.query})
        return;
      }
          
  }
  next();
});


router.beforeEach((to, from, next) => {
  if (to.path !== '/' && !to.matched.length) {
    next('/');
  } else {
    next();
  }
});