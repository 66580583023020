import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_router_view),
    _createVNode(_component_notifications, {
      duration: 2500,
      position: _ctx.isMobile ? 'top center mt-[500px] top-6' : 'top center mt-[400px]'
    }, null, 8, ["position"])
  ]))
}