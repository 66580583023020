
import { Component, ComputedOptions, MethodOptions, computed, defineComponent, ref } from 'vue';

import { Amplify, Storage } from 'aws-amplify';
import { v4 as uuid } from "uuid";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import Loader from '../loader.vue';
import Spinner from '../Spinner.vue';
import { FilePond as FilePondInstance } from 'filepond';

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
) as unknown as Component<any, any, any, ComputedOptions, MethodOptions>;

Amplify.configure({
  Auth: {
    identityPoolId: process.env.VUE_APP_AWS_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.VUE_APP_AWS_POOL_REGION, // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    AWSS3: {
      bucket: process.env.VUE_APP_AWS_S3_BUCKET, //REQUIRED -  Amazon S3 bucket name
      region: process.env.VUE_APP_AWS_S3_REGION, //OPTIONAL -  Amazon service region
    }
  }
});

export default defineComponent({
    components: {
    "file-pond": FilePond,
    Loader,
    Spinner
},
    props:{
        files: {type: Array, required: true},
        fileSize: {type: Number, default: 3000000},
    },
    emits:["update:modelValue", "onUploaded"],
    setup(props, {emit}){

        const myFiles = computed({
            get: ()=> props.files,
            set: (v)=>{
                emit("update:modelValue", v);
            }
        }) 
        const fileSizeMB = computed(()=> props.fileSize/ 1000000)

        const shouldUpload = ref(false);
        const isLoading = ref(false)
        const showSizeError = ref(false)
        const filepond = ref(null as unknown as FilePondInstance)
        const hasNewFile = ref(false)

        const handleFilePondInit = async () => {
            const target = (filepond.value as any)
            const file = target.getFiles()[0].file;
            if(!shouldUpload.value) return;

            
            //file size check
            if(file.size > props.fileSize){
                target.removeFiles();
                myFiles.value = [];
                showSizeError.value = true
                return;
            } 

            hasNewFile.value = true

            isLoading.value = true
            showSizeError.value = false
            try {

                const regs = new RegExp(/[a-zA-Z]/g)
                const name = uuid()+"_"+file.name.match(regs).join("");
                
                const response = await Storage.put(name, file, {contentType: "image/png"});
                
                const url = `https://${process.env.VUE_APP_AWS_S3_BUCKET}.s3.${process.env.VUE_APP_AWS_S3_REGION}.amazonaws.com/public/${response.key}`
                shouldUpload.value = false;
                emit("onUploaded", url)
                isLoading.value = false 
            } catch (error) {
                console.log("Error uploading file: ", error);
                isLoading.value = false 
                filepond.value?.removeFiles();
            }
        }

        const removeFileHandler = ()=>{
            hasNewFile.value = false;
        }

        const setUploader = ()=>{
            shouldUpload.value = true;
        }
        
        return {
            isLoading,
            filepond,
            myFiles,
            fileSizeMB,
            showSizeError,
            hasNewFile,
            setUploader,
            removeFileHandler,
            handleFilePondInit
        }
    }
})
