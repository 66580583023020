<template>
    <div class="lg:flex block h-[100vh] sm:w-[444px] w-[90%] mx-auto">
        <div class=" h-[100vh] pt-4 relative">
            <div class="w-full mx-auto">
                <div class="flex justify-center items-center w-40 m-auto mb-5">
                    <img src="../../assets/images/logo/logo.svg" alt=""  class="rounded-[20px] my-2"/>
                </div>
                <div class="flex flex-col justify-center items-center">
                    <p class="text-[#1E2234] lg:text-3xl md:text-3xl sm:text-3xl text-2xl font-[500] mb-3">Sign Up!</p>
                    <p class="text-gray-400 text-sm text-center gry lg:w-[440px] md:w-[440px] w-[92%]">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                        Doloremque, consequatur.
                    </p>
                </div>
                <div class="mt-12 mx-auto h-auto">
                    <form>
                        <div class="relative z-0 w-full mb-6 group ">
                            <input type="text" name="email" id="floating_email"
                                class="block py-3 pl-5 pr-10 email rounded-lg w-full text-sm text-gray-900 bg-transparent border border-[#B4B4B4] focus:ring-vendorPrimary focus:border focus:border-vendorPrimary appearance-none focus:outline-none focus:ring-0 peer"
                                placeholder=" " required />
                            <label for="floating_email"
                                class="peer-focus:font-medium absolute text-sm text-gray-800 dark:text-gray-400 duration-300 pl-5 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-5 peer-focus:pl-0 peer-focus:text-vendorPrimary peer-focus:text-base peer-focus:bg-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >

                
                                Email
                            </label>
                            <i class="fa-solid fa-at absolute top-3.5 right-4 at-icon icon"></i>
                        </div>
                        <div class="relative z-0 w-full mb-6 group">
                            <input type="password" name="pwd" id="pwd"
                                class="block py-3 pl-5 pr-10 pwd rounded-lg w-full text-sm text-gray-900 bg-transparent border border-[#B4B4B4] focus:ring-vendorPrimary focus:border focus:border-vendorPrimary appearance-none focus:outline-none focus:ring-0 peer"
                                placeholder=" " required />
                            <label for="pwd"
                                class="peer-focus:font-medium absolute text-sm text-gray-800 dark:text-gray-400 duration-300 pl-5 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-5 peer-focus:pl-0 peer-focus:text-vendorPrimary peer-focus:text-base peer-focus:bg-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                Password
                            </label>
                            <i class="fa-solid fa-lock  absolute top-3.5 right-4 lock-icon icon"></i>
                        </div>
                        <div class="relative z-0 w-full mb-2 group">
                            <input type="password" name="c-pwd" id="c-pwd"
                                class="block py-3 pl-5 pr-10 c-pwd rounded-lg w-full text-sm text-gray-900 bg-transparent border border-[#B4B4B4] focus:ring-vendorPrimary focus:border focus:border-vendorPrimary appearance-none focus:outline-none focus:ring-0 peer"
                                placeholder=" " required />
                            <label for="c-pwd"
                                class="peer-focus:font-medium absolute text-sm text-gray-800 dark:text-gray-400 duration-300 pl-5 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-5 peer-focus:pl-0 peer-focus:text-vendorPrimary peer-focus:text-base peer-focus:bg-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                               Confirm  Password
                            </label>
                            <i class="fa-solid fa-lock  absolute top-3.5 right-4 c-lock icon"></i>
                        </div>
                       

                        <div class="mt-14">
                            <button
                                class="w-full bg-vendorPrimary border border-transparent hover:bg-white hover:border hover:border-vendorPrimary hover:text-vendorPrimary py-3 rounded-lg text-white text-sm ease-in-out duration-200">
                                 Sign Up
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="flex justify-center items-center absolute bottom-8 w-full">
                <span class="flex flex-wrap mx-3">
                    <p class=" text-gray-400 text-sm text-center mr-2">Already have an account?</p>
                    <router-link to="/login">
                       <p class="text-vendorPrimary text-sm font-semibold">Log in</p>
                    </router-link>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "sign-up",
};
</script>

<style scoped>
</style>
