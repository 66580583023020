
import { defineComponent, onMounted, ref } from '@vue/runtime-core';
import ProductVariant from '../../components/foods/products-variant.vue';
import { useProduct } from '@/core/composables/useProduct';
import TitledCard from '@/components/cards/TitledCard.vue';
import HandleDocument from '@/components/HandleDocument.vue';
import { Product } from '@/models';
import FoodForm from '@/components/foods/FoodForm.vue';
import { useRouter, useRoute } from "vue-router";
import Spinner from '@/components/Spinner.vue';

export default defineComponent({
  name: "transaction",
  components: { ProductVariant, TitledCard, HandleDocument, FoodForm, Spinner },
  setup() {
    const { product, create, getProduct, loading } = useProduct();
    const showVariant = ref(false);
    const showQuantity = ref(false);
    const router = useRouter();
    const route = useRoute()

    const createOrUpdateProduct = (data: Product) => {
      console.log(data);
      
      create(data)
      // router.push({ name: "foods" })
    }

    onMounted(() => {
      getProduct(route.params.uuid)
      console.log(product.value);
      
    })

    return {
      loading,
      product,
      showVariant,
      showQuantity,
      createOrUpdateProduct,

    }
  }
});
