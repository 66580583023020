
import TitledCard from '@/components/cards/TitledCard.vue'
import { useRoute } from 'vue-router';
import { defineComponent, onMounted, ref } from 'vue';
import { useTransaction } from '@/core/composables/useTransaction';
import Spinner from '@/components/Spinner.vue';
import NumberFormatter from '@/components/NumberFormatter.vue';

export default defineComponent({
    components: { TitledCard, Spinner,  NumberFormatter },
    setup() {

        const { isLoading, transaction,
            getTransaction } = useTransaction();

        const uuId = useRoute().params.uuid;

        onMounted(async () => {
            await getTransaction(uuId);
            console.log(transaction.value.extra);
            
        });


        return {
            transaction,
            isLoading,
        }
    }
})
