
import TitledCard from '@/components/cards/TitledCard.vue'
import { useRoute } from 'vue-router';
import { defineComponent, onMounted, ref } from 'vue';
import { useOrder } from '@/core/composables/useOrder';
import Spinner from '@/components/Spinner.vue';
import NumberFormatter from '@/components/NumberFormatter.vue';
import { vendorNotification } from '@/main';

export default defineComponent({
    components: { TitledCard, Spinner, NumberFormatter },
    setup() {

        const { isLoading, order,
            getOrder, acceptOrder, rejectOrder, readyForPickup, completeOrder } = useOrder();

        const uuId = useRoute().params.uuid;
        const rejectionNote = ref('out of stock');
        const packageCodeModal = ref(false);
        const packageCode = ref('');

        onMounted(async () => {
            await getOrder(uuId);
        });

        const acceptOrderHandler = async () => {
            await acceptOrder(uuId);
        }

        const rejectOrderHandler = async () => {
            await rejectOrder(uuId, rejectionNote.value);
        }

        const processOrder = async () => {
            await readyForPickup(uuId);
        }
        const completeOrderHandler = async () => {
            const payload = {
                code: packageCode.value,
                order_uuid: order.value.uuid ?? ''
            }
            let response = await completeOrder(payload);
            if (response === 1) {
                packageCodeModal.value = false;
                return
            }
            vendorNotification({
                type: 'error',
                title: "Request Failed",
                text: 'Invalid Package'
            })
        }

        return {
            order,
            isLoading,
            processOrder,
            packageCode,
            acceptOrderHandler,
            rejectOrderHandler,
            packageCodeModal,
            completeOrderHandler
        }
    }
})
