
import { defineComponent, computed } from 'vue';

export default defineComponent({
   props:{
       label: { type:String },
       modelValue: { type:String },
       type: { type:String , default:'text'},
       icon: { type:String },
       required: { type:Boolean , default: false},
       placeholder: { type:String , default: 'Enter your details'},
   },
   emits:['update:modelValue'],
   setup(props, { emit }){
       const value = computed({
           get:()=> props.modelValue,
           set:(v)=> emit("update:modelValue", v)
       });


       return  {
           value
       }
   }
})
