import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { Order, ResponseMeta } from '@/models';
import { cloneDeep } from "lodash";
import { vendorNotification } from '@/main';

import { defaultOrder, defaultResponseMeta } from '@/core/data/orderData';

interface OrderComposable {
    isLoading: Ref<boolean>,
    orders:Ref<Order[]>,
    order: Ref<Order>,
    getOrder: (uuid:any) => void;
    getOrders: (page?:number, search?:any) => void;
    acceptOrder: (uuid:any) => void;
    rejectOrder: (uuid:any, note:string) => void;
    readyForPickup: (uuid:any) => void;
    completeOrder:(payload:{code: string, order_uuid: string}) => Promise<number>
    paginationData: Ref<ResponseMeta>;
}

export const useOrder = (): OrderComposable => {
    const isLoading = ref(false);
    const order: Ref<Order> = ref(cloneDeep(defaultOrder) as unknown as Order);
    const orders: Ref<Order[]> = ref([]);
    const paginationData: Ref<ResponseMeta> = ref(cloneDeep(defaultResponseMeta) as unknown as ResponseMeta);

    const getOrders = async (page?:number, search?:any) => {
        isLoading.value = true;
        let response = await NetworkService.get('vendor/order?page='+page+'&search='+search);
        orders.value = response.data.data.map((e:any)=>{
            e.foods = JSON.parse(e.foods)
            return e;
        })
        let meta = {...response.data.links, ...response.data.meta}
        paginationData.value = meta
        isLoading.value = false;
    }
    const getOrder = async (uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.get('vendor/order/' + uuid);
        let data = response.data.data;
        data.foods = JSON.parse(data.foods)
        order.value = data;
        isLoading.value = false;
    }

    const acceptOrder = async (uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.post('vendor/order/' + uuid+'/accept', {});
        if(response.status ==200){ await getOrder(uuid); }
        isLoading.value = false;
    }

    const rejectOrder = async (uuid:any, note: string) => {
        isLoading.value = true;
        let response = await NetworkService.post('vendor/order/' + uuid+'/reject', {note});
        if(response.status == 200){ await getOrder(uuid); }
        isLoading.value = false;
    }
    
    const readyForPickup = async (uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.post('vendor/order/' + uuid+'/process', {});
        if(response.status == 200){ await getOrder(uuid); }
        isLoading.value = false;
    }

    const completeOrder = async (payload:{code: string, order_uuid: string}) => {
        isLoading.value = true;
        let response = await NetworkService.post('vendor/order/complete', payload);
        if(response.status == 200){
            isLoading.value = false;
            await getOrder(payload.order_uuid);
            return 1;
        }
        isLoading.value = false;
        return 0;
    }
    

    return {
        isLoading,
        order,
        orders,
        paginationData,
        getOrders,
        acceptOrder,
        rejectOrder,
        completeOrder,
        readyForPickup,
        getOrder,                                                                                      
    }
}