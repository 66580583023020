import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "font-semibold text-[#1E2234] text-sm whitespace-wrap" }
const _hoisted_3 = { class: "text-xs whitespace-nowrap my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberFormatter = _resolveComponent("NumberFormatter")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: 'transactions.show', params: { uuid: _ctx.transaction.uuid } },
    class: "flex justify-between items-start mb-7 border-b py-1"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.transaction.description), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$filters.formatDate(_ctx.transaction.created_at)), 1)
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("p", {
          class: _normalizeClass([_ctx.transaction.type == 'credit' ? 'text-green': 'text-red' , "text-sm font-semibold whitespace-nowrap"])
        }, [
          _createTextVNode(_toDisplayString(_ctx.transaction.type == 'credit' ? '+' : '-') + " ", 1),
          _createVNode(_component_NumberFormatter, {
            amount: _ctx.transaction.amount
          }, null, 8, ["amount"])
        ], 2)
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}