
import { useFood } from '@/core/composables/useFood';
import { Ref, defineComponent, onMounted, ref } from 'vue'
import Spinner from '@/components/Spinner.vue';
import { Product } from '@/models';
import { useProduct } from '@/core/composables/useProduct';
import confirmationModal from '@/components/modals/confirmationModal.vue'

export default defineComponent({
  components: { Spinner, confirmationModal },
  setup() {
    const { foods, getFoods, } = useFood();
    const { products, getProducts, paginationData, loading, deleteProduct } = useProduct();

    const currentProduct = ref();
    const isFirstPage = ref();
    const isLastPage = ref();
    const next = ref();
    const prev = ref();
    const search = ref("")

    const fetchProducts = async (page?: number) => {
      await getProducts(page, search.value);

      isFirstPage.value = 1;
      isLastPage.value = paginationData.value.last_page;
      next.value = paginationData.value.next;
      prev.value = paginationData.value.prev;
    };
    const handleDeleteProduct = (uuid:string)=>{
      deleteProduct(currentProduct.value.uuid);
      setTimeout(()=>{
        window.location.reload();
      }, 1000)
    }

    onMounted(() => {5
      fetchProducts(1);
      // console.log(foods.value);
    })
    const searchMethod = () => {
      fetchProducts(1)
    }

    const nextPage = (page: number) => {
      fetchProducts(page);
    };

    const prevPage = (page: number) => {
      fetchProducts(page);
    };

    return {
      loading,
      products,
      next,
      prev,
      nextPage,
      prevPage,
      paginationData,
      isFirstPage,
      isLastPage,
      search,
      currentProduct,
      searchMethod,
      deleteProduct,
      handleDeleteProduct
    }
  },
})
