import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './assets/tailwind.css';
import './assets/css/dashboard.css';
import 'tw-elements';
// import $ from 'jquery'
import store from "./store";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import moment from 'moment'
import './assets/js/dashboard.js';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const app = createApp(App);

/**
 * @params {date} date to be converted to timeago
 * @returns returns timeAgo
 */
app.config.globalProperties.$filters = {
  formatDate(date: any) {
    return moment(date).format('MMMM Do YYYY, h:mm:ss a')
  },
}

// prepare notification
export const vendorNotification = ({type, title, text, btnTitle, btnClass}:{type: string, title: string, text: string, btnTitle?:string, btnClass?:string}) => {
  app.config.globalProperties.$swal({
    title: title,
    text: text,
    icon: type, // You can use 'success', 'error', 'warning', 'info', etc.
    confirmButtonText: btnTitle ?? 'OK',
    customClass: {
      confirmButton: `custom-confirm-button-${btnClass ?? 'primary'}`,
    },
  });
}

var $ = require('jquery')
app.use(router)
app.use(store)
app.component("Multiselect", Multiselect)
app.component('EasyDataTable', Vue3EasyDataTable);
app.use(VueSweetalert2);
app.mount('#app');
