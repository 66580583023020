import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { User, Food } from "@/models";

interface FoodComposable {
    getFoods:(payload:string)=> void,
    getFood:(payload:string)=> void,
    foods: Ref<[Food]>,
    food: Ref<Food>,
    loading: Ref<Boolean>,
}


export const useFood = ():FoodComposable =>{
    const foods = ref();
    const food = ref();
    const loading = ref(true);

    const getFoods = async (url:string) =>  {
        loading.value= true;
        let response =  await NetworkService.get(url);
        console.log(response);
            
        foods.value = response.data.data;
        loading.value= false;
    }
    
    const getFood = async (url:string) =>  {
        loading.value= true;
        let response =  await NetworkService.get(url);
        
        if (response) {
            food.value = response.data.data;
        }
        loading.value= false;
     }

    return {
        getFood,
        loading,
        getFoods,
        foods,
        food
    }

}