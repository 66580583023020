
import { computed, defineComponent, ref, watch } from "@vue/runtime-core"
import { useStore } from "vuex";
import { useToggle } from "@/core/composables/useToggle";
import NetworkService from "@/core/services/network-service";
import { vendorNotification } from '@/main';

import { useUser } from "@/core/composables/useUser";
import { isIOSDevice } from "@/core/services/app-service";

import { Actions } from "@/store/enums/StoreEnums";
import { useRouter, useRoute, Router } from "vue-router";
import GoLiveComponent from "@/components/GoLiveComponent.vue";

export default defineComponent({
    components: { GoLiveComponent },
    name: 'navbar',

    setup() {
        
        const { open: openSideBar, close } = useToggle('sidebar', 'layer');
        const store = useStore();
        const user = computed(() => store.getters.currentUser);
        const { getUserDetails } = useUser()
        const router = useRouter();

        const signOut = () => {
            store.dispatch(Actions.LOGOUT).then(() => {
                router.push({ name: "login" });
            });
        };

        const isIOS = computed(() => isIOSDevice());


        return { user, openSideBar, isIOS, signOut }


    }
});
